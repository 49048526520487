// extracted by mini-css-extract-plugin
export var caseContactFormSection = "bk_k3";
export var caseStudyBackgroundContainer = "bk_k6";
export var caseStudyBackgroundContainer__ship = "bk_k7";
export var caseStudyBackground__bgColor = "bk_k5";
export var caseStudyBackground__lineColor = "bk_k0";
export var caseStudyHead__imageWrapper = "bk_kX";
export var caseStudyProjectsSection = "bk_k4";
export var caseStudyQuote__bgLight = "bk_kY";
export var caseStudyQuote__bgRing = "bk_kZ";
export var caseStudySolution__ring = "bk_k8";
export var caseStudySolution__ringTwo = "bk_k9";
export var caseStudyTech = "bk_lb";
export var caseStudy__bgDark = "bk_kV";
export var caseStudy__bgLight = "bk_kT";